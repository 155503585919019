import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';

import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';
import {Route, Switch} from 'react-router-dom';
import history from './utils/history';
import * as serviceWorker from './serviceWorker';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/base.scss';
import Main from './Pages/Main';
import UserProvider from './containers/UserProvider';
import configureStore from './configureStore';

import './config/i18n';
import {configDefaultApi} from './helpers/api';
import AppAdmin from './Layout/AppAdmin';

const initialState = {};
const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('root');

configDefaultApi();

ReactDOM.render(
  <Provider store={store}>
    <UserProvider>
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/admin" extract component={AppAdmin} />
          <Route path="" component={Main} />
        </Switch>
      </ConnectedRouter>
    </UserProvider>
  </Provider>,
  MOUNT_NODE,
);

serviceWorker.unregister();
