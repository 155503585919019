import React from 'react';
import LoadingOverlay from 'react-loading-overlay';

const BlockLoading = (props) => {
  const {active, children} = props;
  return (
    <LoadingOverlay styles={{
      overlay: (base) => ({
        ...base,
        background: 'black',
        opacity: 0.5,
      }),
    }} active={active} spinner text="">
      {children}
    </LoadingOverlay>
  );
};

export default BlockLoading;
