import {Route} from 'react-router-dom';
import React, {lazy, Suspense} from 'react';
import Loader from 'react-loaders';

import {ToastContainer} from 'react-toastify';
import {
  userIsNotAuthenticated,
  userIsAuthenticated,
} from '../../containers/Auth/auth';
import CommonPageLoading from '../../components/CommonPageLoading';
import Logout from '../../Pages/AuthPages/Logout';

const UIComponents = lazy(() => import('../../Pages/UIComponents'));

const Login = userIsNotAuthenticated(
  lazy(() => import('../../Pages/AuthPages/Login')),
);
const TopPage = userIsNotAuthenticated(lazy(() => import('../../Pages/Top')));

const ForgetPassword = userIsNotAuthenticated(
  lazy(() => import('../../Pages/AuthPages/ForgetPassword')),
);
const ResetPassword = userIsNotAuthenticated(
  lazy(() => import('../../Pages/AuthPages/ResetPassword')),
);
const ResetPasswordSuccess = userIsNotAuthenticated(
  lazy(() => import('../../Pages/AuthPages/ResetPasswordSuccess')),
);
const RegisterAccount = userIsNotAuthenticated(
  lazy(() => import('../../Pages/AuthPages/RegisterAccount')),
);
const AccountActive = userIsNotAuthenticated(
  lazy(() => import('../../Pages/AuthPages/AccountActive')),
);
const AccountPayment = userIsAuthenticated(
  lazy(() => import('../../Pages/AuthPages/AccountPayment')),
);
const AccountPaymentActive = userIsAuthenticated(
  lazy(() => import('../../Pages/AuthPages/AccountPaymentActive')),
);

const Simulation = userIsAuthenticated(
  lazy(() => import('../../Pages/Simulations')),
);

const EditMail = userIsAuthenticated(
  lazy(() => import('../../Pages/AuthPages/Mail/EditMail')),
);

const EditAccount = userIsAuthenticated(
  lazy(() => import('../../Pages/AuthPages/Account/EditAccount')),
);
const EditMailSendSuccess = userIsAuthenticated(
  lazy(() => import('../../Pages/AuthPages/Mail/EditMail/EditMailSendSuccess')),
);

const NewMailActive = userIsAuthenticated(
  lazy(() => import('../../Pages/AuthPages/Mail/NewMailActive')),
);

const Policy = lazy(() => import('../../Pages/Policy/Policy'));
const Terms = lazy(() => import('../../Pages/Terms/Terms'));
const AppMain = () => {
  return (
    <>
      <Suspense fallback={<CommonPageLoading />}>
        <Route path="/login" component={Login} />
      </Suspense>
      <Suspense fallback={<CommonPageLoading />}>
        <Route exact path="/" component={TopPage} />
      </Suspense>
      <Suspense fallback={<CommonPageLoading />}>
        <Route path="/forget-password" component={ForgetPassword} />
      </Suspense>
      <Suspense fallback={<CommonPageLoading />}>
        <Route path="/reset-password" component={ResetPassword} />
      </Suspense>
      <Suspense fallback={<CommonPageLoading />}>
        <Route
          path="/reset-password-success"
          component={ResetPasswordSuccess}
        />
      </Suspense>
      <Suspense fallback={<CommonPageLoading />}>
        <Route path="/register-account" component={RegisterAccount} />
      </Suspense>
      <Suspense fallback={<CommonPageLoading />}>
        <Route path="/account-active" component={AccountActive} />
      </Suspense>
      <Suspense fallback={<CommonPageLoading />}>
        <Route path="/payment" component={AccountPayment} />
      </Suspense>
      <Suspense fallback={<CommonPageLoading />}>
        <Route
          path="/payment-active"
          component={AccountPaymentActive}
        />
      </Suspense>

      <Suspense fallback={<CommonPageLoading />}>
        <Route path="/simulation" component={Simulation} />
      </Suspense>
      <Suspense fallback={<CommonFallBack />}>
        <Route path="/edit-account" component={EditAccount} />
      </Suspense>
      <Suspense fallback={<CommonFallBack />}>
        <Route exact path="/edit-mail" component={EditMail} />
      </Suspense>
      <Suspense fallback={<CommonFallBack />}>
        <Route exact path="/edit-mail/send" component={EditMailSendSuccess} />
      </Suspense>
      <Suspense fallback={<CommonFallBack />}>
        <Route path="/new-email" component={NewMailActive} />
      </Suspense>
      <Suspense fallback={<CommonFallBack />}>
        <Route path="/policy" component={Policy} />
      </Suspense>
      <Suspense fallback={<CommonFallBack />}>
        <Route path="/terms" component={Terms} />
      </Suspense>
      <Suspense fallback={<CommonPageLoading />}>
        <Route path="/components" component={UIComponents} />
      </Suspense>

      <Route path="/logout" component={Logout} />
      {/* <Route exact path="/" render={() => <Redirect to="/top" />} /> */}
      <ToastContainer />
    </>
  );
};

const CommonFallBack = () => {
  return (
    <div className="loader-container">
      <div className="loader-container-inner">
        <div className="text-center">
          <Loader type="ball-pulse-sync" />
        </div>
        <h6 className="mt-3">Loading...</h6>
      </div>
    </div>
  );
};
export default AppMain;
