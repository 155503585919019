export const NavSimulationItems = [
  {step: 1, title: '1.'},
  {step: 2, title: '2.'},
  {step: 3, title: '3.'},
  {step: 4, title: '4.'},
  {step: 5, title: '5.'},
  {step: 6, title: '6.'},
  {step: 7, title: '7.'},
  {step: 8, title: '8.'},
  {step: 9, title: '9.'},
  {step: 10, title: '10.'},
];
export const INPUT_1 = 1;
export const INPUT_2 = 2;
export const INPUT_3 = 3;
export const INPUT_4 = 4;
export const INPUT_5 = 5;
export const INPUT_6 = 6;
export const INPUT_7 = 7;
export const INPUT_8 = 8;
export const INPUT_9 = 9;
export const INPUT_10 = 10;
export const COUNT_ITEMS_FEE = 9;
export const COUNT_ITEM_FEE_TYPE_4 = 12;
export const COUNT_RECORD_FEE_NAME = 6;
export const COUNT_CHILD = 6;
export const LIMIT_FIELD_FEE = 3;
export const LIMIT_FIELD_FEE_OTHER = 4;
export const TOTAL_FIELD_TYPE_8 = 16;
export const TOTAL_FIELD_TYPE_9 = 18;
export const TOTAL_FIELD_TYPE_11 = 12;

export const KIND_OF_SCHOOL = [
  {text: '私立', value: 1},
  {text: '公立', value: 2},
];

export const KIND_OF_HIGH_SCHOOL = [
  {text: '私立', value: 1},
  {text: '公立', value: 2},
  {text: 'なし', value: 0},
];
export const KIND_OF_UNIVERSITY = [
  {text: '私立', value: 1},
  {text: '国立', value: 2},
  {text: '国立医学部', value: 3},
  {text: '私立医学部', value: 5},
  {text: '短大', value: 4},
  {text: 'なし', value: 0},
];
export const KIND_OF_LIVING = [
  {text: '自宅', value: 1},
  {text: '賃貸', value: 2},
  // {text: '寮', value: 3},
];
export const MARRIAGE_AMOUNT = [
  {text: 'なし', value: 0, amount: 0},
  {text: '標準', value: 1, amount: 113},
  {text: '全額', value: 2, amount: 524},
];
export const MARRIAGE_AMOUNT_DEFAULT = [0, 192, 469];
export const CHILD_SEX = {
  male: 1,
  female: 2,
};
export const MARRIAGE_AGE_DEFAULT = {
  male: 31,
  female: 29,
};

export const SIMULATION_RESULT_ACTIVE_TAB_LIFE_CASE = {
  stay_at_home_type: '1',
  nursing_home_type: '2',
  sudden_death_type: '3',
};

export const SIMULATION_RESULT_ACTIVE_TAB_REPORT_TYPE = {
  type_of_property_owned: '1',
  type_of_income_and_expenditure: '2',
  type_of_inheritance: '3',
};

export const SIMULATION_RESULT_TABS_VERTICAL = [
  {step: '1', title: '基本結果'},
  {step: '2', title: '要介護になった場合'},
  {step: '3', title: '万一の事が起こった場合'},
];

export const SIMULATION_RESULT_TABS_HORIZONTAL = [
  {step: '1', title: '預金残高グラフ'},
  {step: '2', title: '年度別収支表'},
  {step: '3', title: '相続税シミュレーション結果'},
];

export const DEFAULT_FONTSIZE_LINE_GRAPH = 11;

export const CURRENCY_UNITS = [
  // {
  //   text: '億',
  //   value: 1e8,
  // },
  {
    text: '億',
    value: 1e4,
  },
  {
    text: '', // đơn vị các số còn lại
    value: 1,
  },
];
export const KIND_INHERITANCE = {
  PRIMARY: 1,
  SECONDARY: 2,
};

export const MAX_PERCENT = 1000;
export const MAX_LENGTH_PERCENT = 5;
export const MIN_PERCENT = 0;
export const MIN_AMOUNT = 1;
export const MAX_AMOUNT = 999999999;
export const MAX_LENGTH_AMOUNT = 9;
export const MAX_AGE = 110;

export const ROLE = {
  ADMIN: 10,
  USER: 1,
};
export const TYPES_MODAL_CONFIRM = {
  temporarily_stop_using: 'temporarily_stop_using',
  cancel: 'cancel',
  resume_using: 'resume_using',
  activate: 'activate',
  change_free: 'change_free',
};
// Delete flag, and stop flag
export const ACCOUNT_STATUS = {
  ACTIVE: 0,
  STOP: 1,
  DELETED: 2,
};
// User status
export const U_STATUS = {
  INACTIVE: 0,
  MAIL_AUTHENTICATED: 1,
  PAID: 2,
  FREE: 3
};

export const ERROR_URL_INVALID = 'URL invalid';

export const INSTALLATION_SESSION_HAS_EXPIRED =
  '設定のセッション有効期限が切れました。';

export const DATASETS_DONUT_GRAPH = {
  LABELS: [
    'その他資産（負債）',
    '自社株',
    '不動産（土地・建物）',
    '退職金',
    '投資（株・投信）',
    '生命保険',
    '現預金',
  ],
  BACKGROUNDCOLOR: [
    '#7CB342',
    '#00B0FF',
    '#0091EA',
    '#FFD180',
    '#FFAB40',
    '#FF9100',
    '#FF6D00',
  ],
  BORDERCOLOR: [
    '#FFFFFF',
    '#FFFFFF',
    '#FFFFFF',
    '#FFFFFF',
    '#FFFFFF',
    '#FFFFFF',
    '#FFFFFF',
  ],
  BORDERWIDTH: 2,
};

export const DEFAULT_NUM = 0;

export const COUNT_HEADER = 504;
export const S1_FROM = 1;
export const S1_C_FROM = 6;
export const S1_TO = 18; // 6x3 (indx 24)

export const S2_CUSTOMER_FROM = 24;
export const S2_CUSTOMER_TO = 39;
export const S2_SPOUSE_FROM = 40;
export const S2_SPOUSE_TO = 55;

export const S3_CUSTOMER_FROM = 56;
export const S3_CUSTOMER_TO = 107;
export const S3_SPOUSE_FROM = 108;
export const S3_SPOUSE_TO = 159;

export const S4_CUSTOMER_FROM = 160;
export const S4_CUSTOMER_TO = 168;
export const S4_SPOUSE_FROM = 169;
export const S4_SPOUSE_TO = 177;

export const S5_TO = 178;
export const S5_FROM = 132; // 6x22 + 178= 310
export const COUNT_FIELD_RECORD_I5 = 22;

export const S6_RENT_FROM = 310;
export const S6_MORTGAGE_FROM = 319;

export const S6_TYPE_13_FROM = 328;

export const S6_TYPE_3_FROM = 337; 
export const S6_TYPE_4_FROM = 346;
export const S6_TYPE_5_FROM = 358;
export const S6_TYPE_6_FROM = 362;
export const S6_TYPE_7_FROM = 366;
export const S6_TYPE_8_FROM = 370;
export const S6_TYPE_9_FROM = 386;
export const S6_TYPE_10_FROM = 404;
export const S6_TYPE_11_FROM = 407;

export const S7_FROM = 419;
export const S7_TO = 452;

export const S8_FROM = 453;
export const S8_FEE_FROM = 472;
export const S8_TO = 477;

export const S9_FROM = 478;

export const S10_FROM = 481;
export const S10_FEE_FROM = 486;
export const S10_TO = 492;

export const IMPORT_COMMENT_FROM = 493;

export const SESSION_KEY_IMPORTING = 'importing';
export const SESSION_KEY_IMPORTED_INPUTS = 'imported_inputs';
export const SESSION_KEY_CHILD_DEL_IDX= 'children_idx_del';
export const KEY_CS_NOT_FOUND = "customer_simulation_not_found";
export const KEY_UN_AUTHEN ="Unauthenticated.";
export const KEY_ERROR_UNKNOW ="common.error_unknow";

export const MAX_ROW_CSFF = 3;
export const MAX_ROW_CSFF_OTHER = 6;

export const GENDER = {
  MALE: 1,
  FEMALE: 2,
};

export const DEFAULT_MALE_AGE = 81;
export const DEFAULT_FEMALE_AGE = 87;
