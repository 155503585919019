import {Redirect, Route} from 'react-router-dom';
import React, {lazy, Suspense} from 'react';
import CommonPageLoading from '../../components/CommonPageLoading';
import {
  userAdminIsAuthenticated,
  userAdminIsNotAuthenticated,
} from '../../containers/AuthAdmin/auth';
import Logout from '../../Pages/Admin/Logout';

const Login = userAdminIsNotAuthenticated(
  lazy(() => import('../../Pages/Admin/Login')),
);

const UserManagement = userAdminIsAuthenticated(
  lazy(() => import('../../Pages/Admin/UserManagement')),
);

const AppAdmin = ({match}) => {
  return (
    <>
      <Suspense fallback={<CommonPageLoading />}>
        <Route path={`${match.url}/login`} extract component={Login} />
      </Suspense>
      <Suspense fallback={<CommonPageLoading />}>
        <Route
          path={`${match.url}/user-management`}
          extract
          component={UserManagement}
        />
      </Suspense>
      <Route path={`${match.url}/logout`} component={Logout} />
      <Route
        exact
        path={`${match.url}/`}
        render={() => <Redirect to={`${match.url}/user-management`} />}
      />
    </>
  );
};

export default AppAdmin;
