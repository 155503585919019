import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

// import { reducer as globalReducer } from './containers/App/ducks';
// import { reducer as userProviderReducer } from './containers/UserProvider/reducer';
import history from './utils/history';

export default function createReducer(injectedReducers = {}) {
  return combineReducers({
    // global: globalReducer,
    // user: userProviderReducer,
    router: connectRouter(history),
    ...injectedReducers,
  });
}
