/* eslint-disable react/no-unescaped-entities */
import React, {memo, useEffect} from 'react';
import {compose, bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import BlockLoading from '../../../components/BlockLoading';
import {saveAuthToken} from '../../../common/appLocalStorage';

const Logout = ({match}) => {
  useEffect(() => {
    console.log('efffect');
    saveAuthToken(null);
    if (match.url.includes('/admin')) {
      window.location.href = `/admin/login`;
    } else {
      window.location.href = `/login`;
    }
  }, [match.url]);
  return (
    <>
      <BlockLoading active>Logout...</BlockLoading>
    </>
  );
};

// mapping root redux state to props of this component
const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, memo)(Logout);
