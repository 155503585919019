import React from 'react';
import Loader from 'react-loaders';
import './style.scss';

const CommonPageLoading = () => {
  return (
    <div className="loader-container">
      <div className="loader-container-inner">
        <div className="text-center">
          <Loader type="ball-pulse-sync" />
        </div>
        <h6 className="mt-3">Loading...</h6>
      </div>
    </div>
  );
};
export default CommonPageLoading;
