import {call, put, select, takeLatest} from 'redux-saga/effects';
import {saveAuthToken} from '../../common/appLocalStorage';
import { U_STATUS } from '../../config/constant';
import {getMe, getMeForAdmin} from '../../helpers/api';
import history from '../../utils/history';
import {loadUserRequest, loadUserResponse, logout} from './actions';

export const getLogonUser = (state) => state.UserProvider.user;
export const getCurrentPath = (state) => state.router.location.pathname;

export function* userLogout() {
  try {
    window.location.replace('/login');
  } catch (e) {
    console.error('User me error');
    yield put(logout(e));
  }
}

export function* callUserProvider() {
  try {

    const urlCurrent = window.location.href;
    const isAdmin = urlCurrent.includes('/admin');

    let response = null;
    if(isAdmin){
      response = yield call(getMeForAdmin); 
    }else{
      response = yield call(getMe); 
    }
    
    const user = response.data.data;
    yield put(loadUserResponse(user));
    console.log(user);
    if(!user.has_active_plan && !isAdmin && user.status !== U_STATUS.FREE){ 
      yield call([history, history.push], `/payment`);
    }
    
  } catch (e) {
    console.error('User me error');
    console.error(e);

    let errorConverted = null;
    if (e instanceof Error) {
      errorConverted = e;
    } else {
      errorConverted = Error(e.toString());
    }
    yield put(loadUserResponse(errorConverted));
  }
}

export function* callLogout() {
  try {
    console.log('logout...');
    saveAuthToken(null);
    const currentPath = yield select(getCurrentPath);
    if (currentPath !== '/login') {
      yield call([history, history.replace], '/login');
    }
  } catch (e) {
    console.error(e);
    window.location.href = '/login';
  }
}

// watch action from UI component then call to saga function
export function* saga() {
  yield takeLatest(loadUserRequest, callUserProvider);
  yield takeLatest(logout, userLogout);
}
