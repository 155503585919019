export const saveAuthToken = (jwtToken) => {
  if (jwtToken === null) {
    console.log("remove token");
    localStorage.removeItem('jwtToken');
  } else {
    localStorage.setItem('jwtToken', jwtToken);
  }
};

export const getAuthToken = () => {
  return localStorage.getItem('jwtToken');
};
