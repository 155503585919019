import {getAuthToken} from '../common/appLocalStorage';
import axiosInstance from '../utils/axios';

export const configDefaultApi = () => {
  const jwtToken = getAuthToken();
  axiosInstance.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;
  axiosInstance.defaults.common = [];
  if (jwtToken) {
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${jwtToken}`;
  }
};

export const postRegisterAccountApi = (payload) => {
  return axiosInstance.post(`/api/v1/signup`, payload);
};

export const getAddressByZipCodeApi = (zipCode) => {
  return axiosInstance.get(`/api/v1/zipcode/search/${zipCode}`);
};

export const activeEmail = (email, token) => {
  return axiosInstance.post(`/api/v1/active-email`, {email, token});
};

export const postLoginApi = (payload) => {
  return axiosInstance.post(`/api/v1/login`, payload);
};

export const getMe = () => {
  return axiosInstance.get(`/api/v1/me`);
};

export const requestSendMailForgotPassword = (email) => {
  return axiosInstance.post(`/api/v1/forgot-password`, {email});
};

export const apiResetPassword = (email, password, token) => {
  return axiosInstance.post(`/api/v1/reset-password`, {
    email,
    u_password: password,
    token,
  });
};

export const checkExpiredTokenToResetPassword = (email, token) => {
  return axiosInstance.post(`/api/v1/check-expired-token-reset-password`, {
    email,
    token,
  });
};

// simulation 1
export const postSimulationInput1Api = (data) => {
  const url = `/api/v1/register-simulation/input1`;
  return axiosInstance.post(url, data);
};

export const copySimulationApi = (csId) => {
  return axiosInstance.get(`/api/v1/simulation/${csId}/copy`);
};

export const getSimulationInput1Api = (param) => {
  return axiosInstance.get(`/api/v1/simulation/input-1`, {params: param});
};

export const getListSimulation = (data) => {
  return axiosInstance.get(`/api/v1/simulation/list`, {params: data});
};
//  Account
export const postEditAccount = (data) => {
  const url = `/api/v1/me`;
  return axiosInstance.post(url, data);
};

export const postChangeMailAccount = (data) => {
  const url = `/api/v1/me/change-email`;
  return axiosInstance.post(url, data);
};

export const postActiveNewEmail = (data) => {
  const url = `/api/v1/me/active-new-email`;
  return axiosInstance.post(url, data);
};

// simulation 2
export const postSimulationInput2Api = (data) => {
  const url = `/api/v1/register-simulation/input2`;
  return axiosInstance.post(url, data);
};

export const getSimulationInput2Api = (csId) => {
  return axiosInstance.get(`/api/v1/simulation/input-2`, {params: {csId}});
};
// simulation 5

export const postSimulationInput5Api = (data) => {
  const url = `/api/v1/register-simulation/input5`;
  return axiosInstance.post(url, data);
};

export const getSimulationInput5Api = (csId) => {
  return axiosInstance.get(`/api/v1/simulation/input-5`, {params: {csId}});
};

// simulation 3
export const postSimulationInput3Api = (data) => {
  const url = `/api/v1/register-simulation/input3`;
  return axiosInstance.post(url, data);
};

export const getSimulationInput3Api = (csId) => {
  return axiosInstance.get(`/api/v1/simulation/input-3`, {params: {csId}});
};

// simulation 4

export const postSimulationInput4Api = (data) => {
  const url = `/api/v1/register-simulation/input4`;
  return axiosInstance.post(url, data);
};

export const getSimulationInput4Api = (csId) => {
  return axiosInstance.get(`/api/v1/simulation/input-4`, {params: {csId}});
};

// simulation 7
export const postSimulationInput7Api = (data) => {
  const url = `/api/v1/register-simulation/input7`;
  return axiosInstance.post(url, data);
};

export const getSimulationInput7Api = (csId) => {
  return axiosInstance.get(`/api/v1/simulation/input-7`, {params: {csId}});
};

// simulation 6

export const postSimulationInput6Api = (data) => {
  const url = `/api/v1/register-simulation/input6`;
  return axiosInstance.post(url, data);
};

export const getSimulationInput6Api = (csId) => {
  return axiosInstance.get(`/api/v1/simulation/input-6`, {params: {csId}});
};
// simulation 10

export const postSimulationInput10Api = (data) => {
  const url = `/api/v1/register-simulation/input10`;
  return axiosInstance.post(url, data);
};

export const getSimulationInput10Api = (csId) => {
  return axiosInstance.get(`/api/v1/simulation/input-10`, {params: {csId}});
};

// Convert blob to text
export const convertBlobToText = (promise) => {
  return Promise.resolve(promise);
};

// Simulation result

export const exportPdfFileApi = (data) => {
  console.log("---exportPdfFileApi data: ", data);
  return axiosInstance.post(`/api/v1/simulation/result/export-pdf-file`, data, { responseType: 'blob' });
};

// Tab horizontal 1

export const getDepositBalanceGraphApi = (data) => {
  return axiosInstance.get(`/api/v1/simulation/result/deposit-balance-graph`, {params: data});
};

// Tab horizontal 2

export const getAnnualIncomeAndExpenditureTableApi = (data) => {
  return axiosInstance.get(`/api/v1/simulation/result/annual-income-and-expenditure`, {params: data});
};

export const downloadExcelFileApi = (data) => {
  return axiosInstance.get(`/api/v1/simulation/result/download-excel-file`, {params: data});
};
// simulation 9

export const postSimulationInput9Api = (data) => {
  const url = `/api/v1/register-simulation/input9`;
  return axiosInstance.post(url, data);
};

export const getSimulationInput9Api = (csId) => {
  return axiosInstance.get(`/api/v1/simulation/input-9`, {params: {csId}});
};
export const getCalculateInput9Api = (param) => {
  return axiosInstance.get(`/api/v1/simulation/calculate/input-9`, {params: param});
};

// Tab horizontal 3

export const getInheritanceTaxSimulationResultsApi = (csId) => {
  return axiosInstance.get(`/api/v1/simulation/result/inheritance-tax-simulation-results`, {params: {csId}});
};

export const postSaveCommentApi = (data) => {
  return axiosInstance.post(`/api/v1/simulation/result/comment`, data);
};
// simulation 8

export const postSimulationInput8Api = (data) => {
  const url = `/api/v1/register-simulation/input8`;
  return axiosInstance.post(url, data);
};

export const getSimulationInput8Api = (csId) => {
  return axiosInstance.get(`/api/v1/simulation/input-8`, {params: {csId}});
};

// payment
export const registerSubscriptionPlanApi = (paymentMethod) => {
  return axiosInstance.post(`/api/v1/payment/plan/subscribe`, {
    payment_method: paymentMethod,
  });
};

export const updateCardApi = (paymentMethod) => {
  return axiosInstance.post(`/api/v1/payment/plan/update-card`, {
    payment_method: paymentMethod,
  });
};

export const resumeSubscriptionApi = (paymentMethod) => {
  return axiosInstance.post(`/api/v1/payment/plan/resume-subscription`, {
    payment_method: paymentMethod,
  });
};

export const deleteSimulationApi = (csId) => {
  return axiosInstance.delete(`/api/v1/simulation/delete/${csId}`);
};

export const getDataCsvApi = (csId) => {
  return axiosInstance.get(`/api/v1/simulation/data-csv`, {params: {csId}});
};

// admin

export const getMeForAdmin = () => {
  return axiosInstance.get(`/api/admin/v1/me`);
};

export const postAdminLoginApi = (payload) => {
  return axiosInstance.post(`/api/admin/v1/login`, payload);
};
// getListUserAp
export const getListUserApi = (data) => {
  return axiosInstance.get(`/api/admin/v1/user-management`, {params: data});
};

// update status account
export const updateStatusAccountApi = (data) => {
  const url = `/api/admin/v1/update-status-account`;
  return axiosInstance.post(url, data);
};

// Get data graph and comment for export PDF
export const getDataGraphsForExportPdfApi = (csId) => {
  return axiosInstance.get(`/api/v1/simulation/result/data-graphs-export-pdf`, {params: {csId}});
};

