import React, {memo, useEffect} from 'react';
import PropTypes from 'prop-types';
import {connect, useDispatch} from 'react-redux';
import {bindActionCreators, compose} from 'redux';

import {initialState, key, reducer} from './reducer';
import {saga} from './saga';
import { actionCreators, loadUserRequest } from './actions';
import { useInjectReducer } from '../../utils/redux_saga/injectReducer';
import { useInjectSaga } from '../../utils/redux_saga/injectSaga';

const UserProvider = (props) => {
  useInjectReducer({key, reducer});
  useInjectSaga({key, saga});

  const {children} = props;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadUserRequest());
  }, [dispatch]);
  return React.Children.only(children);
};

UserProvider.propTypes = {
  changeAccessToken: PropTypes.func,
  loadUserRequest: PropTypes.func,
};

// mapping root redux state to props of this component
const mapStateToProps = (state) => {
  return {
    ...(state[key] || initialState),
  };
};

// mapping action to props
function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo,
)(UserProvider);
