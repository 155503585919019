import {createActions} from 'redux-actions';

// Action Creators
export const actionCreators = createActions(
  'LOAD_USER_REQUEST',
  'LOAD_USER_RESPONSE',
  'LOGOUT',
  {
    prefix: 'UserProvider', // String used to prefix each type
  },
);

export const {loadUserRequest, loadUserResponse, logout} = actionCreators;
