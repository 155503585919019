import React from 'react';
import AppMain from '../../Layout/AppMain';

const Main = () => {
  

  return <AppMain />;
};

export default Main;
