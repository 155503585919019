export const lang = {
  common: {
    installation_session_has_expired: '設定のセッション有効期限が切れました。',
    url_not_found: "URLが見つかりません 。",
    email_not_existed: "メールアドレスが登録されていません。",
    error_reponse_unknown: "エラーが発生しました。管理者へお問い合わせください。",

    modal_title_error: 'エラー',
    error_response_unknown: {
      message: 'エラーが発生しました。管理者へお問い合わせください。',
    },
    error_duplicate_email: {
      message: 'メールアドレスは既に登録されています。',
    },
    error_invalid_value: {
      message: '入力内容が不正です。',
    },
    modal_title_success: '完了',
    modal_content_success: 'Completed !',
    modal_title_delete_success: 'シミュレーション削除 - 完了',
    modal_title_confirm_delete: 'シミュレーション削除 - 確認',
    modal_content_confirm_delete: '削除しますか?',
    modal_content_delete_success: 'シミュレーションの削除が完了しました。',
    user_not_found: {
      message: 'User not found',
    },
    error_type_file: {
      message: 'CSV形式のファイルをアップロードしてください',
    },
    error_header_file: {
      message:
        'CSVデータが正しくない（項目数が不一致）です。再度確認してください。',
    },

    un_authen: {
      message: 'セッションタイムアウトが発生しました。\n再度ログインを行ってください。',
    },

    error_unknow: {
      message: 'システムエラーが発生しました。管理者へ問い合わせください。',
    },
  },

  forget_password: {
    error: {
      email_not_existed: {
        message: 'メールアドレスが登録されていません。',
      },
      email_or_token_invalid: {
        message: 'URLが見つかりません 。',
      },
      same_old_password: {
        message: '現在のパスワードと新しいパスワードは同じ文字列は使用できません。',
      },
    },
  },

  register: {
    response: {
      error: {
        error_api_register: {
          message: 'メールアドレス は既に登録されています。',
        },
      },
    },
  },

  login: {
    response: {
      error: {
        wrong_email_password: {
          message: 'メールアドレスまたはパスワードが間違っています。',
        },
        email_not_active: {
          message: 'アカウントはアクティブ化されていません。 メールを確認してください。',
        },
        user_stopped: {
          message: 'アカウントが一時停止中されました。 管理者に連絡してください。',
        },
      },
    },
  },

  simulation_input: {
    not_found:{
      message: "シミュレーション見つかりません。"
    },
    save_data_false : {
      message: ' 入力内容が不正です。',
    },
    err_duplicate_age: {
      message: 'その他の教育関連費用の年齢が重複しています。',
    },
    invalid_from_to_age: {
      message: 'Must be from_age < to_age',
    },
    rent_err_duplicate_age: {
      message: '賃貸費（毎月の家賃）の年齢が重複しています。',
    },
    mortgage_err_duplicate_age: {
      message: '住宅ローン（毎月の返済額）の年齢が重複しています。',
    },
    // 3
    repair_reserve_err_duplicate_age: {
      message: '修繕積立金等（毎月の金額）の年齢が重複しています。',
    },
    // 4
    repair_cost_err_duplicate_age: {
      message: '修繕費（金額）の年齢が重複しています。',
    },
    // 8
    other_leisure_expenses_err_duplicate_age: {
      message: 'その他の余暇費用の年齢が重複しています。',
    },
    // 9
    other_err_duplicate_age: {
      message: 'その他の料金の年齢が重複しています。',
    },
    // 11
    parental_care_costs_err_duplicate_age: {
      message: '親の介護費用（毎月）の年齢が重複しています。',
    },

    // 13
    other_borrowing_err_duplicate_age: {
      message: 'その他借入(毎月の返済額）の年齢が重複しています。',
    },
  },
  update_status_account: {
    data_has_been_updated: {
      message:
        'データが既に更新されました。画面をリロードし、もう一度やり直してください。',
    },
    status_invalid: {
      message: 'データが既に更新されました。画面をリロードし、もう一度やり直してください。',
    },
  },
  payment :{
    e_p_001:{
      message: 'Payment stripe customer already create',
    },
    e_p_002:{
      message: 'Payment stripe customer already active',
    },
    e_p_003:{
      message: 'User not payment',
    },
    e_p_004:{
      message: 'User already striped_id',
    },
    e_p_005:{
      message: 'Account stripe cancelled',
    },
    e_p_006:{
      message: 'Account stripe ended',
    }
  }
};
