import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import {connectedRouterRedirect} from 'redux-auth-wrapper/history4/redirect';
import CommonPageLoading from '../../components/CommonPageLoading';

import { ROLE } from '../../config/constant';

import {getUser, getUserLoading} from '../UserProvider/reducer';

const locationHelper = locationHelperBuilder({});

export const userAdminIsAuthenticated = connectedRouterRedirect({
  redirectPath: '/admin/login',
  authenticatedSelector: (state) => {
    const user = getUser(state);
    const isAuthenticatedSelector = user !== false && user.u_role === ROLE.ADMIN; 
    return isAuthenticatedSelector;
  },
  authenticatingSelector: (state) => getUserLoading(state),
  wrapperDisplayName: 'UserIsAuthenticated',
  AuthenticatingComponent: CommonPageLoading,
});

export const userAdminIsNotAuthenticated = connectedRouterRedirect({
  redirectPath: (state, ownProps) => {
    return (
      locationHelper.getRedirectQueryParam(ownProps) || '/admin'
    );
  },
  allowRedirectBack: false,
  authenticatedSelector: (state) => {
    const user = getUser(state);
    const IsNotAuthenticated = user === false || user.u_role !== ROLE.ADMIN; 
    return IsNotAuthenticated;
  },
  wrapperDisplayName: 'UserIsNotAuthenticated',
  AuthenticatingComponent: CommonPageLoading,
});
