import {handleActions} from 'redux-actions';
import {loadUserRequest, loadUserResponse, logout} from './actions';

export const key = 'UserProvider';

// state default when component did mount
export const initialState = {
  user: false,
  loading: true,
  success: false,
  errors: [],
};

export const getUser = (state) =>
  state[key] ? state[key].user : initialState.user;

export const getUserLoading = (state) =>
  state[key] ? state[key].loading : initialState.loading;

// load user info saved in localStorage before result from API
// handle action from saga then make states
export const reducer = handleActions(
  {
    [loadUserRequest]: (state) => ({
      ...state,
      loading: true,
      success: false,
      errors: [],
    }),

    [logout]: (state, {payload}) => ({
      ...state,
      errors: payload,
    }),

    [loadUserResponse]: {
      next: (state, {payload}) => {
        return {
          ...state,
          loading: false,
          success: !payload.errors,
          user: payload,
        };
      },
      throw: (state, {payload}) => {
        console.log('exception: ', payload);

        return {
          ...state,
          errors: payload.message,
          user: false,
          loading: false,
          success: false,
        };
      },
    },
  },
  initialState,
);
